import React, { useState } from 'react';

import Tabs from './components/Tabs';
import OverviewCard from '../Card/OverviewCard';
import WebinarCard from '../Card/WebinarCard';
import DemoCard from '../../molecules/DemoCard/DemoCard';
import SpecializationCard from '../Card/SpecializationCard';

import richTextParser from '../../utils/richTextParser';

import {
  Section,
  Wrapper,
  Container,
  Post,
  Sidebar,
} from './styles/index.styled';

const PartnersPost = ({ partner, location }) => {
  const [activeContentIndex, setActiveContentIndex] = useState(0);

  if (!partner) return null;
  const { posts, webinarCard, leadFormCard, partnerSpecializationCard } =
    partner;

  return (
    <Section>
      <Wrapper>
        <Container>
          <Tabs
            items={posts}
            activeTab={activeContentIndex}
            setActiveTab={setActiveContentIndex}
            location={location}
          />
          {posts && posts.length > 0 ? (
            <Post>{richTextParser(posts[activeContentIndex]?.richText)}</Post>
          ) : null}
        </Container>
        <Sidebar>
          {leadFormCard && <DemoCard component={leadFormCard} />}
          {webinarCard && <WebinarCard component={webinarCard} />}
          {partnerSpecializationCard && (
            <SpecializationCard component={partnerSpecializationCard} />
          )}
          <OverviewCard component={partner} />
        </Sidebar>
      </Wrapper>
    </Section>
  );
};

export default PartnersPost;
