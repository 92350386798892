import styled, { css } from 'styled-components';

import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

const backgroundColorMap = {
  primary: '#E1F5FE',
  secondary: '#22252A',
  tertiary: '#FFFFFF',
};

const headingColorMap = {
  primary: colors.gray[900],
  secondary: colors.gray[50],
  tertiary: colors.gray[900],
};

const subheadColorMap = {
  primary: colors.gray[700],
  secondary: colors.gray[50],
  tertiary: colors.gray[700],
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 16px;
  background-color: ${({ backgroundColor }) =>
    backgroundColorMap[backgroundColor] || colors.primary[50]};
  ${atMinWidth.xl`
    flex-direction: ${({ fullWidth }) => (fullWidth ? 'row' : 'column')};
    padding: ${({ fullWidth }) => (fullWidth ? '40px' : '24px')};
  `}
  .demo-button {
    width: 100%;
    justify-content: center;
    ${atMinWidth.xl`
      width: ${({ fullWidth }) => (fullWidth ? 'fit-content' : '100%')};
    `}
  }
`;
export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .heading {
    ${font('text', 'lg', '700')}
    color: ${({ backgroundColor }) =>
      headingColorMap[backgroundColor] || colors.gray[900]}};
    ${atMinWidth.xl`
     ${({ fullWidth }) =>
       fullWidth
         ? css`
             ${font('display', 'md', '900')}
             color: ${({ backgroundColor }) =>
               headingColorMap[backgroundColor] || colors.gray[900]}};
           `
         : css`
             ${font('text', 'lg', '700')}
           `}
    `}
  }
  .subhead {
    ${font('text', 'md', '400')}
    color: ${({ backgroundColor }) =>
      subheadColorMap[backgroundColor] || colors.gray[700]}};
    ${props =>
      props?.fullWidth &&
      css`
        ${font('text', 'lg', '400')}
      `}
  }
`;
