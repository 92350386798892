import React from 'react';

import Image from '../Image';
import Button from '../../molecules/Button';

import { Container, Thumbnail, Text } from './styles/WebinarCard.styled';

const WebinarCard = ({ component }) => {
  if (!component) return null;
  const { backgroundColor, heading, subhead, button, image } = component;

  return (
    <Container backgroundColor={backgroundColor}>
      {image?.gatsbyImageData && (
        <Thumbnail>
          <Image src={image?.gatsbyImageData} alt={image?.title} />
        </Thumbnail>
      )}
      <Text backgroundColor={backgroundColor}>
        {heading && <p className="heading">{heading}</p>}
        {subhead?.subhead && <p className="subhead">{subhead.subhead}</p>}
        {button && (
          <Button
            variant={button?.variant}
            type={button?.type}
            theme={button?.color}
            size="sm"
            className="demo-button"
            href={button?.url}
            form={button?.form}
          >
            {button?.label}
          </Button>
        )}
      </Text>
    </Container>
  );
};

export default WebinarCard;
