import styled from 'styled-components';
import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';
import { atMinWidth } from '../../../atoms/breakpoints';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${atMinWidth.sm`
    display: grid;  
    grid-template-columns: repeat(${({ numOfItems }) => numOfItems}, 1fr);
  `}
`;
export const Button = styled.button`
  width: 100%;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${font('text', 'xs', '900')}
  color: ${colors.common.black};
  text-align: left;
  background-color: ${colors.gray[50]};
  border: none;
  border-bottom: 1px solid ${colors.gray[100]};
  outline: none;
  transition: all 250ms ease-in-out;
  ${atMinWidth.sm`
    ${font('text', 'sm', '900')}
  `}
  ${atMinWidth.sm`
    ${font('text', 'sm', '900')}
    border-right: 1px solid ${colors.gray[100]};
    border-bottom: none;
  `}
  &:hover,
  &:focus,
  &.active {
    background-color: ${colors.common.white};
  }
  &:last-child {
    border-right: none;
  }
`;
