import React from 'react';

import Badge from '../../molecules/Badge';
import Image from '../Image';

import richTextParser from '../../utils/richTextParser';

import {
  Container,
  Content,
  Group,
  Heading,
  OuterWrapper,
  Wrapper,
} from './styles/SpecializationCard.styled';

const SpecializationGroup = ({ heading, body, items }) => {
  if (!heading && !body && (!items || items.length === 0)) return null;

  return (
    <Group>
      {heading && <div className="heading">{heading}</div>}
      {body && <div className="body">{richTextParser(body)}</div>}
      {items && items.length > 0 && (
        <div className="items">
          {items.map(item => (
            <Badge
              key={item?.id}
              text={item?.name}
              background="bg-gray-100"
              color="darkest"
            />
          ))}
        </div>
      )}
    </Group>
  );
};

const SpecializationCard = ({ component }) => {
  const {
    additionalCalloutsHeading,
    additionalCalloutsRichText,
    bestFitForHeading,
    businessSizeHeading,
    businessSizeRichText,
    certifications,
    certificationsHeading,
    engagementPreferenceHeading,
    engagementPreferenceRichText,
    heading,
    segmentsHeading,
    segmentsRichText,
    trades,
    tradesHeading,
  } = component;

  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      <OuterWrapper>
        {(certificationsHeading || certifications?.length > 0) && (
          <Content>
            {certificationsHeading && (
              <div className="heading">{certificationsHeading}</div>
            )}
            {certifications?.length > 0 && (
              <div className="badges">
                {certifications?.map(cert => (
                  <Image
                    key={cert?.id}
                    src={cert?.gatsbyImageData}
                    alt={cert?.title}
                  />
                ))}
              </div>
            )}
          </Content>
        )}
        <Content>
          {bestFitForHeading && (
            <p className="heading show-line">{bestFitForHeading}</p>
          )}
          <Wrapper>
            <SpecializationGroup
              heading={businessSizeHeading}
              body={businessSizeRichText}
            />
            <SpecializationGroup heading={tradesHeading} items={trades} />
            <SpecializationGroup
              heading={segmentsHeading}
              body={segmentsRichText}
            />
            <SpecializationGroup
              heading={engagementPreferenceHeading}
              body={engagementPreferenceRichText}
            />
            <SpecializationGroup
              heading={additionalCalloutsHeading}
              body={additionalCalloutsRichText}
            />
          </Wrapper>
        </Content>
      </OuterWrapper>
    </Container>
  );
};

export default SpecializationCard;
