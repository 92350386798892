import React from 'react';
import useParams, { updateParamSilently } from '../util/useParams';
import { Container, Button } from '../styles/Tabs.styled';

const Tabs = ({ items, activeTab, setActiveTab, location }) => {
  useParams({
    location,
    items,
    setActiveTab,
  });

  if (!items || items.length === 1) return null;

  return (
    <Container numOfItems={items.length || '3'}>
      {items.map((item, index) => (
        <Button
          key={item?.id}
          type="button"
          aria-label={`Click to view ${item?.internalName}`}
          className={`selector ${activeTab === index && 'active'}`}
          onClick={() => {
            setActiveTab(index);
            updateParamSilently(item?.internalName, location, 'partner');
          }}
        >
          {item?.internalName}
        </Button>
      ))}
    </Container>
  );
};

export default Tabs;
