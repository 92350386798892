import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Button from '../../molecules/Button';
import { Container } from './styles/OverviewCard.styled';

const OverviewCard = ({ component }) => {
  if (!component) return null;
  const { overview, website } = component;

  if (!overview?.raw) return null;
  return (
    <Container>
      <p className="heading">Partner Overview</p>
      <span className="subhead">
        {documentToReactComponents(JSON.parse(overview.raw))}
      </span>
      {website && (
        <Button
          variant="outlined"
          theme="tertiary"
          size="sm"
          href={website}
          styles="width: 100%; justify-content: center;"
        >
          View Website
        </Button>
      )}
    </Container>
  );
};

export default OverviewCard;
