import styled from 'styled-components';

import { font } from '../../../atoms/typography';
import { colors } from '../../../atoms/colors';

const backgroundColorMap = {
  primary: '#E1F5FE',
  secondary: '#22252A',
  tertiary: '#FFFFFF',
};

const headingColorMap = {
  primary: colors.gray[900],
  secondary: colors.gray[50],
  tertiary: colors.gray[900],
};

const subheadColorMap = {
  primary: colors.gray[700],
  secondary: colors.gray[50],
  tertiary: colors.gray[700],
};

export const Container = styled.div`
  width: 100%;
  background: ${({ backgroundColor }) =>
    backgroundColorMap[backgroundColor] || colors.primary[50]};
`;
export const Thumbnail = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  .gatsby-image {
    width: 100%;
    height: 100%;
  }
`;
export const Text = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;

  .heading {
    ${font('text', 'lg', '900')}
    color: ${({ backgroundColor }) =>
      headingColorMap[backgroundColor] || colors.gray[900]};
  }

  .subhead {
    ${font('text', 'md', '400')}
    color: ${({ backgroundColor }) =>
      subheadColorMap[backgroundColor] || colors.gray[700]};
  }

  .demo-button {
    width: 100%;
    justify-content: center;
  }
`;
