import styled from 'styled-components';
import { atMinWidth } from '../../../atoms/breakpoints';
import { colors } from '../../../atoms/colors';

export const Section = styled.section`
  width: 100%;
  height: auto;
  background: linear-gradient(
      180deg,
      #f5f5f5 79.74%,
      rgba(245, 245, 245, 0) 100%
    ),
    #ffffff;
  padding: 72px 0px 40px;
`;
export const Wrapper = styled.div`
  width: 100%;
  max-width: 840px;
  margin: 0px auto;
  padding: 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  ${atMinWidth.sm`
    padding: 0px 35px;
  `}
  ${atMinWidth.xl`
    max-width: 1240px;
    display: grid;
    grid-template-columns: 770px 1fr;
  `}
`;
export const Container = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: ${colors.common.white};
`;
export const Post = styled.article`
  width: 100%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
export const Sidebar = styled.aside`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
