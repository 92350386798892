import styled from 'styled-components';
import { colors } from '../../../atoms/colors';
import { font } from '../../../atoms/typography';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  background: ${colors.common.white};
  .heading {
    ${font('text', 'lg', '900')}
    color: ${colors.gray[900]};
  }
  .subhead {
    ${font('text', 'md', '400')}
    color: ${colors.gray[700]};
  }
`;
