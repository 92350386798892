import { useEffect } from 'react';
import { globalHistory } from '@reach/router';
import { navigate } from 'gatsby';

export const updateParamSilently = (internalName, location, target) => {
  const path = globalHistory.location.pathname;
  const newParams = internalName.toLowerCase().replace(/ /g, '-');
  navigate(`${path}?post=${newParams}${target && `#${target}`}`, {
    replace: true,
  });
};

const useParams = ({ location, items, setActiveTab }) => {
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const param = params.get('post');
    if (!param) return;

    if (!items || items.length === 0) return;

    const filteredPosts = items.filter(item => {
      const name = item.internalName.toLowerCase().replace(/ /g, '-');
      return name === param;
    });

    if (filteredPosts.length === 0) return;

    const postIndex = items.indexOf(filteredPosts[0]);
    setActiveTab(postIndex);
  }, [items, location, setActiveTab]);
};

export default useParams;
