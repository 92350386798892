import React from 'react';
import Button from '../Button';
import { Container, Text } from './styles/DemoCard.styled';

const DemoCard = ({ component, fullWidth }) => {
  if (!component) return null;
  const { backgroundColor, heading, subhead, button } = component;

  return (
    <Container
      backgroundColor={backgroundColor}
      fullWidth={fullWidth}
      className="demo-card"
    >
      <Text backgroundColor={backgroundColor} fullWidth={fullWidth}>
        {heading && <p className="heading">{heading}</p>}
        {subhead?.subhead && <p className="subhead">{subhead?.subhead}</p>}
      </Text>
      <Button
        variant={button?.variant}
        type={button?.type}
        theme={button?.color}
        size={fullWidth ? 'xxl' : 'sm'}
        className="demo-button"
        href={button?.url}
        form={button?.form}
      >
        {button?.label}
      </Button>
    </Container>
  );
};

export default DemoCard;
