import styled from 'styled-components';

import { colors } from '../../../atoms/colors';
import { font } from '../../../atoms/typography';
import { spacing } from '../../../atoms/spacing';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing[16]};
  padding: ${spacing[32]};
  border: 1px solid ${colors.gray[100]};
  background: ${colors.gray[50]};
`;

export const Heading = styled.p`
  ${font('text', 'xl', '700')}
  color: ${colors.gray[900]};
`;

export const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing[32]};
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing[8]};

  .heading {
    position: relative;
    ${font('text', 'lg', '700')}
    color: ${colors.gray[900]};
    padding-bottom: 1px;

    &.show-line {
      margin-bottom: ${spacing[4]};
      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
        height: 1px;
        background-color: ${colors.gray[100]};
      }
    }
  }

  .badges {
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing[6]};

    .gatsby-image,
    img {
      max-height: 28px;
      object-fit: contain !important;
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing[24]};
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing[6]};

  .heading {
    ${font('text', 'md', '700')}
    color: ${colors.gray[900]};
    text-decoration: none;
    padding-bottom: 0px;

    &::after {
      content: '';
      visibility: hidden;
    }
  }

  .body,
  .body * {
    ${font('text', 'md', '400')}
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: ${spacing[6]};
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: ${spacing[10]};
  }
`;
